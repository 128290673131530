import { Home, Whatshot, Info } from '@material-ui/icons';
import HomePage from '../pages/Home/HomePage';
import InformationPage from '../pages/Information/InformationPage';
import GraphPage from '../pages/Graph/GraphPage';
import ComparePage from '../pages/Graph/ComparePage';
import MdiPump from '../components/icons/mdipump';
import MdiElevator from '../components/icons/mdielevator';
import MdiLamp from '../components/icons/mdilamp';
import MdiOutside from '../components/icons/mdioutside';
import MdiGarage from '../components/icons/mdigarage';
import MdiCompare from '../components/icons/mdiCompare'

const defaultYears = ['2021', '2020', '2019', '2018', '2017'];
const defaultBuildings = ['A', 'B', 'C', 'D'];

const Routes = [
    {
    path: '/dashboard/home',
    sidebarName: 'Start',
    navbarName: 'Start',
    icon: Home,
    component: HomePage,
    top: true,
    type: "home",
    showInSidebar: true
},
{
    path: '/dashboard/heatpump',
    sidebarName: 'Wärmepumpe',
    navbarName: 'Wärmepumpe',
    icon: MdiPump,
    component: GraphPage,
    top: true,
    years: defaultYears,
    source: 'sWP',
    type: 'graph',
    unit: 'kWh',
    c_unit: 'W',
    c_factor: 1000,
    showInSidebar: true
},
{
    path: '/dashboard/gas',
    sidebarName: 'Gas',
    navbarName: 'Gas',
    icon: Whatshot,
    component: GraphPage,
    top: true,
    years: defaultYears,
    source: 'gB',
    type: 'graph',
    unit: 'm³',
    c_unit: 'm³/h',
    c_factor: 1,
    showInSidebar: true
},
{
    path: '/dashboard/power',
    sidebarName: 'Allgemeinstrom',
    navbarName: 'Allgemeinstrom',
    icon: MdiLamp,
    component: GraphPage,
    top: true,
    years: defaultYears,
    source: 'sB',
    type: 'graph',
    c_unit: 'W',
    c_factor: 1000,
    unit: 'kWh',
    showInSidebar: true
},
{
    path: '/dashboard/elevator',
    sidebarName: 'Aufzug',
    navbarName: 'Aufzug',
    icon: MdiElevator,
    component: GraphPage,
    top: true,
    years: defaultYears,
    source: 'sBK2',
    type: 'graph',
    c_unit: 'W',
    c_factor: 1000,
    unit: 'kWh',
    showInSidebar: true
},
{
    path: '/dashboard/garage',
    sidebarName: 'Tiefgarage',
    navbarName: 'Tiefgarage',
    icon: MdiGarage,
    component: GraphPage,
    top: true,
    years: defaultYears,
    source: 'sBK4',
    type: 'graph',
    c_unit: 'W',
    c_factor: 1000,
    unit: 'kWh',
    showInSidebar: true
},
{
    path: '/dashboard/outside',
    sidebarName: 'Außenbeleuchtung',
    navbarName: 'Außenbeleuchtung',
    icon: MdiOutside,
    component: GraphPage,
    top: true,
    years: defaultYears,
    source: 'sBK3',
    type: 'graph',
    c_unit: 'W',
    c_factor: 1000,
    unit: 'kWh',
    showInSidebar: true
},
{
    path: '/dashboard/compare',
    sidebarName: 'Vergleich',
    navbarName: 'Vergleich Gasverbrauch',
    icon: MdiCompare,
    component: ComparePage,
    top: true,
    years: defaultYears,
    buildings: defaultBuildings,
    sources: ['gA','gB','gC','gD'],
    type: 'compare',
    unit: 'm³',
    c_unit: 'm³/h',
    c_factor: 1,
    showInSidebar: true
},
{
    path: '/dashboard/info',
    sidebarName: 'Information',
    navbarName: 'Information',
    icon: Info,
    component: InformationPage,
    top: false,
    type: 'graph',
    c_unit: 'W',
    c_factor: 1000,
    unit: 'kWh',
    showInSidebar: true
}

];

export default Routes;
