import React, { Component } from 'react';
import MdiIcon from '@mdi/react';
import { mdiChartLine } from '@mdi/js';

class MdiChart extends Component {
    render() {
        var { ...other } = this.props;
        return (
            <MdiIcon {...other} path={mdiChartLine} className={[this.props.className, "MuiSvgIcon-root"].join(' ')} />
        );
    }
}

export default MdiChart;