import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import '../css/historygraphs.css';

class HistoryGraphs extends Component {
    constructor(props) {
        super(props);
        this.max = this.props.history.reduce((max, value) => !(value.current) ? Math.max(value.total, max) : max, 0);
        this.currentYear = new Date().getFullYear();
        this.graphFor = this.graphFor.bind(this);
    }

    componentDidUpdate() {
        if (this.props.data) {
            this.max = Math.max(this.max, this.props.data.value-this.props.history[0].start);
        }
    }

    graphFor(year, active, total, key) {
        let activePercent = active / total * 100;
        let totalPercent = total / this.max * 100;
        let tooltip = "";
        const localeOptions = {maximumFractionDigits: 0};
        var language = window.navigator.userLanguage || window.navigator.language;
        if (active === total) {
            tooltip = active.toLocaleString(language, localeOptions);
        } else {
            tooltip = [active.toLocaleString(language, localeOptions), "von", total.toLocaleString(language, localeOptions)].join(" ");
        }

        return (
            <div className="historyRow" key={key}>
                <Typography variant="caption" className="historyCaption" style={{color: this.props.fcolor}}>{year}</Typography>
                <Tooltip placement="bottom-start" className="historyTooltip" title={tooltip} enterTouchDelay={0}>
                    <div className="historyBar">
                        <div className="historyTotalBar" style={{ width: [totalPercent, '%'].join(''), backgroundColor: this.props.bcolor }}>
                            <div className="historyActiveBar" style={{ width: [activePercent, '%'].join('') , backgroundColor: this.props.fcolor}}></div>
                        </div>

                        <div className="historyMissingBar"></div>
                    </div>
                </Tooltip>
            </div>
        );
    }

    render() {
        let hasData = false;
        if (this.props.data && this.props.data.value) {
            hasData = true;
            this.max = Math.max(this.max, this.props.data.value - this.props.history[0].start);
        }
        return (
            <div className="historyGraphsContainer">
                {this.props.history.map((prop, key) => {
                    let offset = prop.start;
                    if (prop.year === this.currentYear) { 
                        return ( this.graphFor(this.currentYear, hasData ? this.props.data.value - offset : 0, hasData ? this.props.data.value - offset: 0, "bar-f-0") )}
                    return this.graphFor(prop.year, hasData ? this.props.data.history[prop.year].value - offset: 0, prop.total, "bar-" + key);
                })}
            </div>

        )
    }
}

export default HistoryGraphs