import React, { Component } from 'react';
import { Button } from '@material-ui/core';

export default class InformationPage extends Component {
    componentDidMount() {
        document.title = this.props.title;
    }

    render() {
        return (<Button variant="contained" color="primary">Knopf Information</Button>);
    }
}