import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

function ErrorDialog(props) {
    const { hasError, handleClose } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <Dialog
            fullScreen={fullScreen}
            open={hasError}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{"Fehler beim Laden der Daten"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Es gab ein Problem beim Laden der Daten. Bitte versuchen Sie es erneut.
          </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    Erneut versuchen
          </Button>
            </DialogActions>
        </Dialog>

    )

}

export default ErrorDialog;

