import React, { Component } from 'react';
import MdiIcon from '@mdi/react';
import { mdiElevatorPassenger } from '@mdi/js';

class MdiElevator extends Component {
    render() {
        return (
            <MdiIcon path={mdiElevatorPassenger} className={[this.props.className, "MuiSvgIcon-root"].join(' ')} />
        );
    }
}

export default MdiElevator;