import React, { Component } from 'react';
import MdiIcon from '@mdi/react';
import { mdiOutdoorLamp } from '@mdi/js';

class MdiOutside extends Component {
    render() {
        return (
            <MdiIcon path={mdiOutdoorLamp} className={[this.props.className, "MuiSvgIcon-root"].join(' ')} />
        );
    }
}

export default MdiOutside;