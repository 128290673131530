import React, { Component } from 'react';
import { AppBar, Toolbar, IconButton, Typography, LinearProgress } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';

import routes from '../routes/routes';
import '../css/titlebar.css';


class Titlebar extends Component {

    currentPageInfo() {
        let currentpath = this.props.location.pathname;
        for (let i = 0; i < routes.length; ++i) {
            if (routes[i].path === currentpath) {
                return routes[i];
            }
        }
        return undefined;
    }

    leftHeader(currentPage, classes) {
        if (currentPage === undefined) {
            return "<div></div>";
        } else {
            return (
                <>
                    <currentPage.icon className={classes.toolbarIcon2} />
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.titleLeft}>
                        {currentPage.navbarName}
                    </Typography>
                </>
            );
        }
    }

    render() {
        const currentPage = this.currentPageInfo();
        const { classes } = this.props;


        return (
            <AppBar position="absolute" className={clsx(classes.appBar, this.props.open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.props.handleOpen}
                        className={clsx(classes.menuButton, this.props.open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {this.leftHeader(currentPage, classes)}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={[classes.titleRight, "titleRight"].join(' ')}>
                        Verbrauchsinformationen
          </Typography>
                </Toolbar>
                {this.props.loading && <LinearProgress color="secondary" />}
            </AppBar>
        );
    }
}

export default withRouter(Titlebar);