import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import Select from '@material-ui/core/Select'
import ErrorDialog from '../../components/errordialog'
import CompareGraph from '../../components/comparegraph'
import axios from 'axios'
import overviewData from '../../mockdata/overview';

const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};

class ComparePage extends Component {
    constructor(props) {
        super(props);
        this.processData = this.processData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.state = { hasData: false, hasError: false};
        this.historyData = {};
        this.props.sources.map((source) => {
            this.historyData[source] = convertArrayToObject(overviewData[source].history, 'year');
            return;
        });
        this.colors = ['#f44336', '#ff9800', '#ffc107', '#4caf50', '#2196f3', '#9c27b0'];
    }

    processData(data, index) {
        let sensors = ['gA', 'gB', 'gC', 'gD'];
        let sensor = sensors[index % 4];
        let firstTime = data.zaehlerstand[0].time;
        let lastTime = data.zaehlerstand[data.zaehlerstand.length - 1].time;
        let firstDate = new Date(firstTime * 1000);
        let year = firstDate.getFullYear();
        let myData = [];

        if (firstTime !== overviewData.years[year].start !== firstTime) {
            myData = [{ time: overviewData.years[year].startTime, value: this.historyData[sensor][year].start }, ...data.zaehlerstand];
        }
        if (!this.historyData[sensor][year].current && (this.historyData[sensor][year].end !== lastTime)) {
            myData = [...myData, { time: (overviewData.years[year].endTime -1), value: this.historyData[sensor][year].end }];
        }

        let processedYearlyData = myData.map((value) => {
            let date = new Date(value.time * 1000);
            date.setFullYear(1974);
            let result = value.value - this.historyData[sensor][year].start;
            return ({ x: date, y: result });
        }
        );


        let processedCurrentData = myData.map((value, index, array) => {
            let date = new Date(value.time * 1000);
            let amount = value.value;
            if (index === array.length - 1) {
                let result = (amount - array[index - 1].value) / (value.time - array[index - 1].time) * 60 * 60;
                date.setFullYear(1974);
                return ({ x: date, y: result*this.props.c_factor });
            }
            let hours = (array[index + 1].time - value.time) / 60 / 60;
            let amount2 = array[index + 1].value;
            let result = (amount2 - amount) / hours;
            date.setFullYear(1974);
            return ({ x: date, y: result*this.props.c_factor });


        })
        return [processedYearlyData, processedCurrentData];
    }

    loadData() {
        this.props.startLoading();
        this.setState({ hasError: false });
        var dataPath = "https://verbrauch.bogenstrasse13.de/rest/jahresstand.php?zaehler=";
        var dataPaths = this.props.sources.map((prop) => {
            return dataPath + prop;
        });
        var queries = this.props.years.map((prop) => {
            return dataPaths.map((path) => {
                return path + "&jahr=" + prop;
            });
        });
        queries = queries.flat();
        var requests = queries.map((query) => { return axios(query) });
        axios.all(requests).then(axios.spread((...responses) => {
            var processed = responses.map((response, index) => {
                return this.processData(response.data, index);
            });
            var yearlyData = processed.map((data) => { return data[0] });
            var yearlyGraphs = yearlyData.map((data, index) => {
                return (
                    {
                        hoverBorderWidth: 5,
                        hoverBorderColor: this.colors[Math.floor(index/4)],
                        borderColor: this.colors[Math.floor(index/4)],
                        backgroundColor: this.colors[Math.floor(index/4)],
                        fill: false,
                        label: this.props.years[Math.floor(index/4)],
                        type: 'line',
                        data: data
                    }
                );
            });
            var yearlyBuildingGraphs = yearlyData.map((data, index) => {
                return (
                    {
                        hoverBorderWidth: 5,
                        hoverBorderColor: this.colors[Math.floor(index%4)],
                        borderColor: this.colors[Math.floor(index%4)],
                        backgroundColor: this.colors[Math.floor(index%4)],
                        fill: false,
                        label: this.props.years[Math.floor(index%4)],
                        type: 'line',
                        data: data
                    }
                );
            });
            var currentData = processed.map((data) => { return data[1] });
            var currentGraphs = currentData.map((data, index) => {
                return (
                    {
                        hoverBorderWidth: 5,
                        hoverBorderColor: this.colors[Math.floor(index/4)],
                        borderColor: this.colors[Math.floor(index/4)],
                        backgroundColor: this.colors[Math.floor(index/4)],
                        fill: false,
                        label: this.props.years[Math.floor(index/4)],
                        type: 'line',
                        data: data,
                        radius: 2,
                        steppedLine: true
                    }
                );
            });
            var currentBuildingGraphs = currentData.map((data, index) => {
                return (
                    {
                        hoverBorderWidth: 5,
                        hoverBorderColor: this.colors[Math.floor(index%4)],
                        borderColor: this.colors[Math.floor(index%4)],
                        backgroundColor: this.colors[Math.floor(index%4)],
                        fill: false,
                        label: this.props.years[Math.floor(index%4)],
                        type: 'line',
                        data: data,
                        radius: 2,
                        steppedLine: true
                    }
                )
            })
            var data = [...yearlyGraphs, ...currentGraphs, ...yearlyBuildingGraphs, ...currentBuildingGraphs];
            this.setState({ data: data , hasData: true});
            this.props.stopLoading();
        })).catch(errors => { this.setState({ hasError: true }); this.props.stopLoading() });;

    }

    componentDidMount() {
        document.title = this.props.title;
        this.loadData();
    }

    render() {
        
        return (
            <Paper style={{ padding: "10px", height: "100%" }}>
                <div className="toggles">
                    <ToggleButtonGroup
                        exclusive
                        aria-label="text alignment"
                        value={this.props.mode}
                        onChange={this.props.setMode}
                        size="small"
                    >
                        <ToggleButton disabled={!this.state.hasData} value="yearly" aria-label="left aligned">
                            Jahreszählerstand
                    </ToggleButton>
                        <ToggleButton disabled={!this.state.hasData} value="current" aria-label="centered">
                            Verbrauch
                    </ToggleButton>
                    </ToggleButtonGroup>
                    <Select native defaultValue={this.props.compareItem} id="grouped-native-select" onChange={this.props.setCompareItem}>
                        <optgroup label="Häuser">
                            <option value={1}>Haus A</option>
                            <option value={2}>Haus B</option>
                            <option value={3}>Haus C</option>
                            <option value={4}>Haus D</option>
                        </optgroup>
                        <optgroup label="Jahre">
                            <option value={5}>2021</option>
                            <option value={6}>2020</option>
                            <option value={7}>2019</option>
                            <option value={8}>2018</option>
                            <option value={9}>2017</option>
                        </optgroup>
                    </Select>
                    <ToggleButtonGroup
                        aria-label="buildings"
                        value={this.props.activeBuildings}
                        onChange={this.props.setBuildings}
                        size="small"
                        className={this.props.compareMode === "year" ? "visibleGroup" : "hiddenGroup"}
                    >
                        {this.props.buildings.map((building, key) => {
                            return (
                                <ToggleButton
                                    className="yearSelect"
                                    disabled={!this.state.hasData}
                                    value={building}
                                    aria-label={building}
                                    key={key}
                                >
                                    {building}
                                </ToggleButton>
                            )
                        })
                    }
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                        aria-label="years"
                        value={this.props.activeYears}
                        onChange={this.props.setYears}
                        size="small"
                        className={this.props.compareMode !== "year" ? "visibleGroup" : "hiddenGroup"}
                    >
                        {this.props.years.map((year, key) => {

                            return (
                                <ToggleButton className="yearSelect" disabled={!this.state.hasData} value={year} aria-label={year} key={key}>
                                    {year}
                                </ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>
                </div>
                <CompareGraph 
                    data={this.state.data} 
                    years={this.props.years}
                    buildings={this.props.buildings}
                    activeYears={this.props.activeYears}
                    activeBuildings={this.props.activeBuildings}
                    mode={this.props.mode}
                    currentMode={this.props.compareMode}
                    currentItem={this.props.compareItem}
                    unit={this.props.unit}
                    c_unit={this.props.c_unit}
                />
                <ErrorDialog hasError={this.state.hasError} handleClose={this.loadData}/>
            </Paper>
        )
    }
}

export default ComparePage;