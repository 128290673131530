import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExternalLink from '@material-ui/core/Link';
import Sidebar from './components/sidebar';
import Titlebar from './components/titlebar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import routes from './routes/routes';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <ExternalLink color="inherit" href="https://aquisnova.com/">
        aquisnova software
      </ExternalLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    paddingLeft: 16
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbarIcon2: {
    marginRight: 12,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 24,
  },
  menuButtonHidden: {
    display: 'none',
  },
  titleLeft: {
    flexGrow: 1,
  },
  titleRight: {
    alignSelf: 'right',
  },
  drawer: {
    minHeight: '100vh',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    height: '100%',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%'
  },
  fixedHeight: {
    height: 300,
  },
}));

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }} />
  );
}



export default function App() {
  const classes = useStyles();
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = React.useState(large);
  const [loading, setLoading] = React.useState(false);
  const [mode, setMode] = React.useState('yearly');
  const [compareMode, setCompareMode] = React.useState('year');
  const [compareItem, setCompareItem] = React.useState(5);
  const [years, setYears] = React.useState(['2021','2020','2019','2018','2017']);
  const [buildings, setBuildings] = React.useState(['A', 'B', 'C', 'D']);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLoadingStart = () => {
    setLoading(true);
  }

  const handleLoadingStop = () => {
    setLoading(false);
  }
  const handleYearChange = (event, newYears) => {
    setYears(newYears);
  }

  const handleBuildingChange = (event, newBuildings) => {
    setBuildings(newBuildings);
  }

  const handleModeChange = (event, newMode) => {
    if (newMode) {
      setMode(newMode);
    } else {
      setMode(mode);
    }
  }

  const handleCompareModeChange = (event, newMode) => {
    if (newMode) {
      setCompareMode(newMode);
    } else {
      setCompareMode(compareMode);
    }
  }

  const handleCompareItemChange = (event) => {
    let value = event.target.value;
    setCompareItem(value);
    if (value < 5) {
      setCompareMode('building');
    } else {
      setCompareMode('year');
    }

  }
  return (
    <Router>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Helmet>
      <div className={classes.root}>
        <Titlebar classes={classes} open={open} handleOpen={handleDrawerOpen} loading={loading} />
        <Sidebar classes={classes} open={open} handleClose={handleDrawerClose} large={large} />


        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Switch>
              <Route exact path="/" render={() => { return (<Redirect to="/dashboard/home" />) }} />
              {routes.map((prop, key) => {
                if (prop.type === 'graph') {
                  return (
                    <PropsRoute exact title={"Verbrauchsinformationen - " + prop.navbarName} path={prop.path} component={prop.component} classes={classes} startLoading={handleLoadingStart} stopLoading={handleLoadingStop} setMode={handleModeChange} setYears={handleYearChange} mode={mode} activeYears={years} key={key} years={prop.years} source={prop.source} unit={prop.unit} c_unit={prop.c_unit} c_factor={prop.c_factor} />
                  );
                } else if (prop.type === 'compare') {
                  return (
                    <PropsRoute exact title={"Verbrauchsinformationen - " + prop.navbarName} path={prop.path} component={prop.component} classes={classes} startLoading={handleLoadingStart} stopLoading={handleLoadingStop} setMode={handleModeChange} setCompareMode={handleCompareModeChange} setCompareItem={handleCompareItemChange} setYears={handleYearChange} setBuildings={handleBuildingChange} mode={mode} compareMode={compareMode} compareItem={compareItem} activeYears={years} activeBuildings={buildings} key={key} years={prop.years} buildings={prop.buildings} sources={prop.sources} unit={prop.unit} c_unit={prop.c_unit} c_factor={prop.c_factor} />
                  );
                } else {
                  return (
                    <PropsRoute exact title={"Verbrauchsinformationen - " + prop.navbarName} path={prop.path} component={prop.component} classes={classes} startLoading={handleLoadingStart} stopLoading={handleLoadingStop} key={key} />
                  );}
                
              })}
            </Switch>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>

        </main>
      </div>
    </Router>
  );
}
