import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ErrorDialog from '../../components/errordialog'
import Graph from '../../components/graph'
import axios from 'axios'
import overviewData from '../../mockdata/overview';

const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};

class GraphPage extends Component {
    constructor(props) {
        super(props);
        this.processData = this.processData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.state = { hasData: false, hasError: false};
        this.historyData = convertArrayToObject(overviewData[this.props.source].history, 'year');
        this.colors = ['#f44336', '#ff9800', '#ffc107', '#4caf50', '#2196f3', '#9c27b0'];
    }

    processData(data) {
        let firstTime = data.zaehlerstand[0].time;
        let lastTime = data.zaehlerstand[data.zaehlerstand.length - 1].time;
        let firstDate = new Date(firstTime * 1000);
        let year = firstDate.getFullYear();
        let myData = [];

        if (firstTime !== overviewData.years[year].start !== firstTime) {
            myData = [{ time: overviewData.years[year].startTime, value: this.historyData[year].start }, ...data.zaehlerstand];
        }
        if (!this.historyData[year].current && (this.historyData[year].end !== lastTime)) {
            myData = [...myData, { time: (overviewData.years[year].endTime -1), value: this.historyData[year].end }];
        }

        let processedYearlyData = myData.map((value) => {
            let date = new Date(value.time * 1000);
            date.setFullYear(1974);
            let result = value.value - this.historyData[year].start;
            return ({ x: date, y: result });
        }
        );


        let processedCurrentData = myData.map((value, index, array) => {
            let date = new Date(value.time * 1000);
            let amount = value.value;
            if (index === array.length - 1) {
                let result = (amount - array[index - 1].value) / (value.time - array[index - 1].time) * 60 * 60;
                date.setFullYear(1974);
                return ({ x: date, y: result*this.props.c_factor });
            }
            let hours = (array[index + 1].time - value.time) / 60 / 60;
            let amount2 = array[index + 1].value;
            let result = (amount2 - amount) / hours;
            date.setFullYear(1974);
            return ({ x: date, y: result*this.props.c_factor });


        })
        return [processedYearlyData, processedCurrentData];
    }

    loadData() {
        this.props.startLoading();
        this.setState({ hasError: false });
        var dataPath = "https://verbrauch.bogenstrasse13.de/rest/jahresstand.php?zaehler=" + this.props.source;
        var queries = this.props.years.map((prop) => {
            return dataPath + "&jahr=" + prop;
        });
        var requests = queries.map((query) => { return axios(query) });
        axios.all(requests).then(axios.spread((...responses) => {
            var processed = responses.map((response) => {
                return this.processData(response.data);
            });
            var yearlyData = processed.map((data) => { return data[0] });
            var yearlyGraphs = yearlyData.map((data, index) => {
                return (
                    {
                        hoverBorderWidth: 5,
                        hoverBorderColor: this.colors[index],
                        borderColor: this.colors[index],
                        backgroundColor: this.colors[index],
                        fill: false,
                        label: this.props.years[index],
                        type: 'line',
                        data: data
                    }
                );
            });
            var currentData = processed.map((data) => { return data[1] });
            var currentGraphs = currentData.map((data, index) => {
                return (
                    {
                        hoverBorderWidth: 5,
                        hoverBorderColor: this.colors[index],
                        borderColor: this.colors[index],
                        backgroundColor: this.colors[index],
                        fill: false,
                        label: this.props.years[index],
                        type: 'line',
                        data: data,
                        hidden: (index !== 0),
                        radius: 2,
                        steppedLine: true
                    }
                );
            });
            var data = [...yearlyGraphs, ...currentGraphs];
            this.setState({ data: data , hasData: true});
            this.props.stopLoading();
        })).catch(errors => { this.setState({ hasError: true }); this.props.stopLoading() });;

    }

    componentDidMount() {
        document.title = this.props.title;
        this.loadData();
    }

    render() {
        
        return (
            <Paper style={{ padding: "10px", height: "100%" }}>
                <div className="toggles">
                    <ToggleButtonGroup
                        exclusive
                        aria-label="text alignment"
                        value={this.props.mode}
                        onChange={this.props.setMode}
                        size="small"
                    >
                        <ToggleButton disabled={!this.state.hasData} value="yearly" aria-label="left aligned">
                            Jahreszählerstand
                    </ToggleButton>
                        <ToggleButton disabled={!this.state.hasData} value="current" aria-label="centered">
                            Verbrauch
                    </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                        aria-label="years"
                        value={this.props.activeYears}
                        onChange={this.props.setYears}
                        size="small"
                    >
                        {this.props.years.map((year, key) => {

                            return (
                                <ToggleButton className="yearSelect" disabled={!this.state.hasData} value={year} aria-label={year} key={key}>
                                    {year}
                                </ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>
                </div>
                <Graph data={this.state.data} years={this.props.years} activeYears={this.props.activeYears} mode={this.props.mode} unit={this.props.unit} c_unit={this.props.c_unit}/>
                <ErrorDialog hasError={this.state.hasError} handleClose={this.loadData}/>
            </Paper>
        )
    }
}

export default GraphPage;