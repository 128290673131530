const overviewData = {
    years: {
        2017: {
            startTime: 1483225200,
            endTime: 1514761200,
            current: false
        },
        2018: {
            startTime: 1514761200,
            endTime: 1546297200,
            current: false
        },
        2019: {
            startTime: 1546297200,
            endTime: 1577833200,
            current: false
        },
        2020: {
            startTime: 1577833200,
            endTime: 1609455600,
            current: false,   
        },
        2021: {
            startTime: 1609455600,
            endTime: 1640991600,
            current: true 
        }
    },
    sWP: {
        history: [{
            year: 2021,
            current: true,
            start: 26526.401,
            startTime: 1609455600,
            endTime: 1640991600,
        },  
        {
            year: 2020,
            current: false,
            start: 17468.214,
            end: 26526.401,
            total: 9058.187,
            startTime: 1577833200,
            endTime: 1609455600,
        },
        {
            year: 2019,
            current: false,
            start: 6744.585,
            end: 17468.214,
            total: 10723.629,
            startTime: 1546297200,
            endTime: 1577833200,
        },
        {
            year: 2018,
            current: false,
            start: 1931.369,
            end: 6744.585,
            total: 4813.216,
            startTime: 1514761200,
            endTime: 1546297200,
        },
        {
            year: 2017,
            current: false,
            start: 552.649,
            end: 1931.369,
            total: 1378.72,
            startTime: 1483225200,
            endTime: 1514761200,
        }
        ]
    },
    gA: {
        history: [{
            year: 2021,
            current: true,
            start: 70399.213
        }, 
        {
            year: 2020,
            current: false,
            start: 63190.559,
            end: 70399.213,
            total: 7208.654
        },
        {
            year: 2019,
            current: false,
            start: 55661.484,
            end: 63190.559,
            total: 7529.075
        },
        {
            year: 2018,
            current: false,
            start: 43985.522,
            end: 55661.484,
            total: 11675.962
        },
        {
            year: 2017,
            current: false,
	    start: 28887.370,
            end: 43985.522,
            total: 15098.152
        }
        ]
    },
    gB: {
        history: [{
            year: 2021,
            current: true,
            start: 43808.404
        },
        {
            year: 2020,
            current: false,
            start: 40713.854,
            end: 43808.404,
            total: 3094.550
        },
        {
            year: 2019,
            current: false,
            start: 37223.021,
            end: 40713.854,
            total: 3490.833
        },
        {
            year: 2018,
            current: false,
            start: 31355.781,
            end: 37223.021,
            total: 5867.240
        },
        {
            year: 2017,
            current: false,
            start: 22908.05,
            end: 31355.781,
            total: 8447.731
        }
        ]
    },
    gC: {
        history: [{
            year: 2021,
            current: true,
            start: 44927.818
        },{
            year: 2020,
            current: false,
            start: 38734.856,
            end: 44927.818,
            total: 6192.96
        },
        {
            year: 2019,
            current: false,
            start: 32251.037,
            end: 38734.856,
            total: 6483.819
        },
        {
            year: 2018,
            current: false,
            start: 25305.913,
            end: 32251.037,
            total: 6945.124
        },
        {
            year: 2017,
            current: false,
            start: 17213.681,
            end: 25305.913,
            total: 8092.233
        }
        ]
    },
    gD: {
        history: [{
            year: 2021,
            current: true,
            start: 32776.423
        },{
            year: 2020,
            current: false,
            start: 27696.306,
            end: 32776.423,
            total: 5080.117
        },
        {
            year: 2019,
            current: false,
            start: 22336.160,
            end: 27696.306,
            total: 5360.146
        },
        {
            year: 2018,
            current: false,
            start: 16201.353,
            end: 22336.160,
            total: 6134.8068
        },
        {
            year: 2017,
            current: false,
            start: 9686.403,
            end: 16201.353,
            total: 6513.95
        }
        ]
    },
    sBK2: {
        history: [
            {
                year: 2021,
                current: true,
                start: 3281.474
            },
            {
                year: 2020,
                current: false,
                start: 2671.113,
                end: 3281.474,
                total: 610.343
            },
            {
                year: 2019,
                current: false,
                start: 2048.456,
                end: 2671.113,
                total: 622.657
            },
            {
                year: 2018,
                current: false,
                start: 1418.938,
                end: 2048.456,
                total: 629.518
            },
            {
                year: 2017,
                current: false,
                start: 797.04,
                end: 1418.938,
                total: 621.898
            }
        ]
    },
    sBK3: {
        history: [
            {
                year: 2021,
                current: true,
                start: 19474.782
            }
            ,{
                year: 2020,
                current: false,
                start: 15978.364,
                end: 19474.782, 
                total: 3496.418
            },
            {
                year: 2019,
                current: false,
                start: 12329.976,
                end: 15978.364,
                total: 3648.388
            },
            {
                year: 2018,
                current: false,
                start: 7822.068,
                end: 12329.976,
                total: 4507.908
            },
            {
                year: 2017,
                current: false,
                start: 4040.16,
                end: 7822.068,
                total: 3781.908
            }
        ]
    },
    sBK4: {
        history: [
            {
                year: 2021,
                current: true,
                start: 25916.493
            },
            {
                year: 2020,
                current: false,
                start: 22905.449,
                end: 25916.493,
                total: 3011.044
            },
            {
                year: 2019,
                current: false,
                start: 19728.835,
                end: 22905.449,
                total: 3176.614
            },
            {
                year: 2018,
                current: false,
                start: 16623.374,
                end: 19728.835,
                total: 3105.461
            },
            {
                year: 2017,
                current: false,
                start: 10780.56,
                end: 16623.374,
                total: 5842.814
            }
        ]
    },
    sB: {
        value: 1333.16,
        history: [
            {
                year: 2021,
                current: true,
                start: 31360.212
            },
            {
                year: 2020,
                current: false,
                start: 28111.193,
                end: 31360.212,
                total: 3249.019
            },
            {
                year: 2019,
                current: false,
                start: 24456.418,
                end: 28111.193,
                total: 3654.775
            },
            {
                year: 2018,
                current: false,
                start: 20576.891,
                end: 24456.418,
                total: 3879.527
            },
            {
                year: 2017,
                current: false,
                start: 16428.75,
                end: 20576.891,
                total: 4148.141
            }
        ]
    }
}

export default overviewData;
