import React, { Component } from 'react';
import MdiIcon from '@mdi/react';
import { mdiCompare } from '@mdi/js';

class MdiCompare extends Component {
    render() {
        return (
            <MdiIcon path={mdiCompare} className={[this.props.className, "MuiSvgIcon-root"].join(' ')} />
        );
    }
}

export default MdiCompare;
