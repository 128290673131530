import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import InfoPanel from '../../components/infopanel';
import overviewData from '../../mockdata/overview';
import axios from 'axios';
import ErrorDialog from '../../components/errordialog';
import Divider from '@material-ui/core/Divider';

export default class HomePage extends Component {

    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this);
        this.state = { data: {}, hasError: false };
    }

    loadData() {
        this.props.startLoading();
        this.setState({ hasError: false });
        axios('https://verbrauch.bogenstrasse13.de/rest/current.php')
            .then((response) => { this.setState({ data: response.data }); this.props.stopLoading(); })
            .catch((error) => { this.setState({ hasError: true }); this.props.stopLoading(); });
    }

    componentDidMount() {
        document.title = this.props.title;
        this.loadData();
    }

    render() {

        return (
            <>
                <Grid container spacing={3}>
                    {/* Chart */}
                    <InfoPanel name='Wärmepumpe' unit='kWh' info={overviewData.sWP} data={this.state.data.wpB} path="/dashboard/heatpump" fcolor="#f44336" bcolor="#ffcdd2"/>
                    <InfoPanel name='Gas' unit={['m', <sup key="mySup">3</sup>]} info={overviewData.gB} data={this.state.data.gasB} path="/dashboard/gas" fcolor="#ff9800" bcolor="#ffe0b2"/>
                    <InfoPanel name='Strom Haus B' unit='kWh' info={overviewData.sB} data={this.state.data.stB} path="/dashboard/power" fcolor="#ffc107" bcolor="#ffecb3"/>
                    <InfoPanel name='Aufzug Haus B' unit='kWh' info={overviewData.sBK2} data={this.state.data.k2} path="/dashboard/elevator" fcolor="#4caf50" bcolor="#c8e6c9"/>
                    <InfoPanel name='Tiefgarage' unit='kWh' info={overviewData.sBK4} data={this.state.data.k4} path="/dashboard/garage" fcolor="#2196f3" bcolor="#bbdefb" />
                    <InfoPanel name='Außenbeleuchtung' unit='kWh' info={overviewData.sBK3} data={this.state.data.k3} path="/dashboard/outside" fcolor="#9c27b0" bcolor="#e1bee7"/>
                </Grid>
		<Divider variant="middle" style={{marginTop: "20px", marginBottom: "20px"}}/>
		<Grid container spacing={3}>
		    <InfoPanel
			name='Gas (Haus A)'
			unit={['m', <sup key="mySup">3</sup>]}
			info={overviewData.gA}
			data={this.state.data.gasA}
			path="/dashboard/compare"
                        fcolor="#607d8b"
			bcolor="#cfd8dc"
		    />
		    <InfoPanel
                        name='Gas (Haus C)'
                        unit={['m', <sup key="mySup">3</sup>]}
                        info={overviewData.gC}
                        data={this.state.data.gasC}
                        path="/dashboard/compare"
			fcolor="#607d8b"
			bcolor="#cfd8dc"
                    />
		    <InfoPanel
                        name='Gas (Haus D)'
                        unit={['m', <sup key="mySup">3</sup>]}
                        info={overviewData.gD}
                        data={this.state.data.gasD}
                        path="/dashboard/compare"
                        fcolor="#607d8b"
                        bcolor="#cfd8dc"
                    />
		</Grid>
                <ErrorDialog hasError={this.state.hasError} handleClose={this.loadData} />
            </>
        );
    }

}
