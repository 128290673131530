import React, { Component } from 'react';
import MdiIcon from '@mdi/react';
import { mdiLightbulb } from '@mdi/js';

class MdiLamp extends Component {
    render() {
        return (
            <MdiIcon path={mdiLightbulb} className={[this.props.className, "MuiSvgIcon-root"].join(' ')} />
        );
    }
}

export default MdiLamp;