import React, { Component } from 'react'
import Chart from "chart.js";
import 'chartjs-adapter-date-fns';
import {de} from 'date-fns/locale';
import Logo from '../data/icon.png';
import Typography from '@material-ui/core/Typography';


class Graph extends Component {
    chartRef = React.createRef();
    chart = undefined;

    constructor(props) {
        super(props);
        this.setupGraph = this.setupGraph.bind(this);
        this.setVisibility = this.setVisibility.bind(this);
        this.currentUnit = props.unit;
    }

    setupGraph() {
        const myChartRef = this.chartRef.current.getContext("2d");
        Chart.defaults.global.defaultFontFamily = "'Roboto', 'Helvetica', 'Arial', sans-serif";
        let language = window.navigator.userLanguage || window.navigator.language;
        this.chart = new Chart(myChartRef, {
            data: {
                //Bring in data
                datasets: this.props.data
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                scales: {
					xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'month',
                            unitStepSize: 1,
                            displayFormats: {
                               'month': 'MMM'
                            }
                        },
                        ticks: {
                            fontSize: 16,
                            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                            min: new Date(1973,  12,  1,  0,  0,  0),
                            max: new Date(1974, 11, 30, 23, 59, 59)
                        },
                        adapters: {
                            date: {
                                locale: de
                            }
                        }
						
					}],
					yAxes: [{
						gridLines: {
							drawBorder: false
						},
						scaleLabel: {
							display: true,
                            labelString: 'Jahresverbrauch',
                            fontSize: 16
                        },
                        ticks: {
                            beginAtZero: true,
                            fontSize: 16,
                            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                        }
					}]
                },
                hover: {
                    mode: 'dataset',
                    intersect: true
                },
                tooltips: {
                    callbacks: {
                      label: (tooltipItem, data) => {
                        var tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;
                        var digits = 0;
                        if (tooltipItem.datasetIndex >= (data.datasets.length/2)) {
                            digits = 3;
                        }
                        return tooltipValue.toLocaleString(language, {maximumFractionDigits: digits}) + ' ' + this.currentUnit;
                      },
                      title: function(tooltipItems, data) {
                          let tooltipItem = tooltipItems[0];
                          let DSindex = tooltipItem.datasetIndex;
                          let index = tooltipItem.index;
                          let x = data.datasets[DSindex].data[index].x;
                          return x.toLocaleString(language, {month: "long", day: "numeric", hour: "numeric", minute: "numeric"});
                    },
                    }
                }

                //Customize chart options
            }
        });
    }

    setVisibility() {
        if (!this.chart) return;
        let possibleYears = this.props.years;
        let offset1 = 0;
        let offset2 = 5;
        this.chart.options.scales.yAxes[0].scaleLabel.labelString = "Jahresverbrauch / " + this.props.unit;
        this.currentUnit = this.props.unit;
        if (this.props.mode === 'current') {
            offset1 = 5;
            offset2 = 0;
            this.chart.options.scales.yAxes[0].scaleLabel.labelString = "Durchschnittsverbrauch / " + this.props.c_unit;
            this.currentUnit = this.props.c_unit;
        }
        possibleYears.forEach((year, index) => {
            if (this.props.activeYears.includes(year)) {
                this.chart.getDatasetMeta(index+offset1).hidden = false;
                this.chart.getDatasetMeta(index+offset2).hidden = true;
            } else {
                this.chart.getDatasetMeta(index+offset1).hidden = true;
                this.chart.getDatasetMeta(index+offset2).hidden = true;
            }
        });
            
        this.chart.update(0);
    }

    render() {
        if (!this.chart && this.props.data) {
            this.setupGraph();
            
        }
        if (this.chart) {
            this.setVisibility();
        }

        return (
            <div>
            {!!(this.props.data) ||  
                <div className="loadingDiv" hidden={!this.props.data}>
                <img alt="spinning logo" className="loadingLogo" src={Logo}/>
                <Typography align="center" variant="h5" component="h2">Daten werden geladen...</Typography>
            </div>
            }
            <div className="chart">
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
            </div>
        )

    }
}

export default Graph;