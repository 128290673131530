import React, { Component } from 'react';
import MdiIcon from '@mdi/react';
import { mdiCar } from '@mdi/js';

class MdiGarage extends Component {
    render() {
        return (
            <MdiIcon path={mdiCar} className={[this.props.className, "MuiSvgIcon-root"].join(' ')} />
        );
    }
}

export default MdiGarage;