import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import MdiChart from './icons/mdichart';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import { CircularProgress } from '@material-ui/core';
import HistoryGraphs from './historygraphs';
import '../css/infopanel.css';
import { Link as RouterLink, withRouter } from 'react-router-dom';

class InfoPanel extends Component {
    constructor(props) {
        super(props);
        if (this.props.data) {
            this.lastUpdateUNIX = this.props.data.lastUpdate;
            this.lastUpdate = new Date(this.props.data.lastUpdate * 1000);
        }
        this.fground = this.props.fcolor;
        this.bground = this.props.bcolor;
    }

    render() {
        var language = window.navigator.userLanguage || window.navigator.language;
        if (this.props.data) {
            if (!this.lastUpdateUNIX || (this.lastUpdateUNIX !== this.props.data.lastUpdate)) {
                this.lastUpdate = new Date(this.props.data.lastUpdate * 1000);
                this.lastUpdateUNIX = this.props.data.lastUpdate;
            }
        }
        const localeOptions = {maximumFractionDigits: 0};
        return (
            <Grid item xs={12} md={4}>
                <Paper>
                    <Typography display="block" align="center" variant="overline">{this.props.name}</Typography>
                    {this.props.info.history !== undefined &&
                        <>
                            <Typography align="center" variant="h4" component="h2">{this.props.data ? (this.props.data.value-this.props.info.history[0].start).toLocaleString(language, localeOptions) : "---"} {this.props.unit}</Typography>
                            <Typography align="center" variant="caption" display="block">{this.props.data ? "Stand: " + this.lastUpdate.toLocaleString(language) : "Wird geladen..."}</Typography>
                            <Divider variant='middle' />
                            <HistoryGraphs history={this.props.info.history} data={this.props.data} fcolor={this.fground} bcolor={this.bground}/>
                            <div className="infoPanelBottom">
                                <div className="infoPanelChartIcon">
                                    <Tooltip title={'Graphen ansehen'}>
                                        <RouterLink style={{ color: "inherit" }} to={this.props.path}>
                                            <MdiChart size="20px" style={{color: this.fground}}/>
                                        </RouterLink>
                                    </Tooltip>
                                </div>
                                <div className="infoPanelInfoIcon">
                                    <Tooltip title={this.props.data ? "Letzte Aktualisierung: " + this.lastUpdate.toLocaleString(language) : "Wird geladen..."}>
                                        <InfoIcon fontSize="small" style={{color: this.fground}} />
                                    </Tooltip>
                                </div>
                            </div>
                        </>
                    }
                    {this.props.info.history === undefined &&
                        <div style={{ textAlign: 'center', paddingTop: '30px', paddingBottom: '30px' }}>
                            <CircularProgress />
                        </div>
                    }

                </Paper>
            </Grid>
        )
    }
}

InfoPanel.defaultProps = {
    name: 'Strom',
    unit: 'kWh',
    data_id: 'stromB',
    color: "#ff0000",

};

export default withRouter(InfoPanel);